// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/loading-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-page{
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat top/cover;
  padding: 0;
}
.loading-progress-circle{
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 78px;
    height: 78px;
}
.loading-progress-circle svg{
    transform: rotateY(180deg);
}
.loading-progress-circle span{
    position: absolute;
    top: 50%;
    font-size: 18px;
    left: 50%;
    transform: translate(-50%,-50%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Loading/Loading.css"],"names":[],"mappings":"AAAA;EACE,uEAAuE;EACvE,UAAU;AACZ;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,WAAW;IACX,YAAY;AAChB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,eAAe;IACf,SAAS;IACT,+BAA+B;AACnC","sourcesContent":[".loading-page{\r\n  background: url(../../assets/images/loading-bg.jpg) no-repeat top/cover;\r\n  padding: 0;\r\n}\r\n.loading-progress-circle{\r\n    position: absolute;\r\n    bottom: 40px;\r\n    left: 50%;\r\n    transform: translateX(-50%);\r\n    width: 78px;\r\n    height: 78px;\r\n}\r\n.loading-progress-circle svg{\r\n    transform: rotateY(180deg);\r\n}\r\n.loading-progress-circle span{\r\n    position: absolute;\r\n    top: 50%;\r\n    font-size: 18px;\r\n    left: 50%;\r\n    transform: translate(-50%,-50%);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
