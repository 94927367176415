// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
button,
input hr a {
  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  color: white;
  -webkit-user-select: none;
          user-select: none;
  font-family: "Lexend", sans-serif;
  -webkit-tap-highlight-color: transparent;
}
#root {
  height: inherit;
  width: 100%;
  overflow-x: hidden;
}

html,
body {
  overflow: hidden;
  color: #fff;
  height: 100vh;
  background-color: #000;
}

button {
  cursor: pointer;
  position: relative;
  transition: .1s;
}
button:active{
  transform: scale(0.95);
  filter: brightness(0.9);
}
.loading-screen {
  background-color: var(--tg-theme-bg-color);
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.btn-loading-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}
.page-wrapper {
  width: 100%;
  overflow-x: hidden;
  height: inherit;
  padding: 14px 16px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;;;;;;;;;;;;;EAaE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,SAAS;EACT,UAAU;EACV,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;EACZ,yBAAiB;UAAjB,iBAAiB;EACjB,iCAAiC;EACjC,wCAAwC;AAC1C;AACA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,QAAQ;EACR,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');\n*,\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nul,\nli,\nbutton,\ninput hr a {\n  background: none;\n  border: none;\n  outline: none;\n  box-sizing: border-box;\n  border: none;\n  margin: 0;\n  padding: 0;\n  text-decoration: none;\n  list-style: none;\n  color: white;\n  user-select: none;\n  font-family: \"Lexend\", sans-serif;\n  -webkit-tap-highlight-color: transparent;\n}\n#root {\n  height: inherit;\n  width: 100%;\n  overflow-x: hidden;\n}\n\nhtml,\nbody {\n  overflow: hidden;\n  color: #fff;\n  height: 100vh;\n  background-color: #000;\n}\n\nbutton {\n  cursor: pointer;\n  position: relative;\n  transition: .1s;\n}\nbutton:active{\n  transform: scale(0.95);\n  filter: brightness(0.9);\n}\n.loading-screen {\n  background-color: var(--tg-theme-bg-color);\n  position: absolute;\n  inset: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 999;\n}\n.btn-loading-icon {\n  position: absolute;\n  top: 50%;\n  right: 16px;\n  transform: translateY(-50%);\n}\n.page-wrapper {\n  width: 100%;\n  overflow-x: hidden;\n  height: inherit;\n  padding: 14px 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
