import React from 'react'
import PageWrapper from 'UI/PageWrapper'
import './Home.css'
type Props = {}

const Home = (props: Props) => {
  return (
    <PageWrapper className='home-page'>
        <div className='user-bar'>
            <div className='user'>
                <img src="img/avatar-placeholder.png" alt="" />
                <span>name</span>
            </div>

            <div className='balance'>
                <img src="img/coin.png" alt="" />
                <span>0.12</span>
            </div>
        </div>
        <img src="img/tv.png" alt="" />
        <div className="actions">
            <img src="img/hero/1.png" alt="" className='hero' />
            <div className="btn-container">
                <button className='sell'>Sell</button>
                <button className='sell buy'>Buy</button>
            </div>
            <div className="btn-container">
                <button className='deposit'>Deposit</button>
                <button className='deposit withdraw'>Withdraw</button>
            </div>
        </div>
    </PageWrapper>
  )
}

export default Home