import {
  RouterProvider,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import tg from "utils/tg";
import LoadingScreen from "UI/LoadingScreen";
function App() {
  const navigation = useNavigate();
  // const { AppContext, AppContextValue } = useAppData();
  const element = useRoutes(routes);
  const location = useLocation();

  const reloadBackButton = () => {
    if (tg.BackButton.isVisible) return false;
    tg.BackButton.onClick(() => {
      navigation(-1);
    });
    tg.BackButton.show();
  };

  useEffect(() => {
    tg.expand();
    tg.disableVerticalSwipes();
    reloadBackButton();
    tg.onEvent("viewportChanged", reloadBackButton);
    
    // const isFirstLaunch = localStorage.getItem('isFirstLaunch')
    // if(isFirstLaunch && location.pathname === '/') navigation('/tap')
  }, []);
  if (!element) return null;
  return (
    
     <>
      <AnimatePresence mode="sync">
        {React.cloneElement(element, { key: location.pathname })}
      </AnimatePresence>
      <LoadingScreen/></>
  );
}

export default App;
