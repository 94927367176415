import Home from "pages/Home/Home";
import Loading from "pages/Loading/Loading";
import { RouteObject } from "react-router-dom";


const routes:RouteObject[] = [
  {
    path: "/",
    element: <Loading />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  ]
  export default routes