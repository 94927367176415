import React, { useEffect, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import "./Loading.css";
import { useNavigate } from "react-router-dom";

type Props = {};

const Loading = (props: Props) => {
  const [percentage, setPercentage] = useState(0);
    const nav = useNavigate()
  useEffect(() => {
    if (percentage === 100) {
        nav('/home')
        return;
    }
    const timeout = setTimeout(() => {
      setPercentage((prev) => prev + 1);
    }, 30);
    return () => clearTimeout(timeout);
  }, [percentage]);

  const radius = 21; // Радиус круга
  const circumference = 2 * Math.PI * radius; // Длина окружности
  const offset = (percentage / 100) * circumference; // Обратное заполнение

  return (
    <PageWrapper className="loading-page">
      <div className="loading-progress-circle">
        <span>{percentage}%</span>
        <svg viewBox="0 0 50 50">
          {/* Фон круга */}
          <circle
            cx={25}
            cy={25}
            r={radius}
            fill="none"
            stroke="#D9D9D9"
            strokeWidth={2}
          />
          {/* Заполняющая линия */}
          <circle
            cx={25}
            cy={25}
            r={radius}
            fill="none"
            stroke="#00BFBF"
            strokeWidth={8}
            strokeDasharray={circumference}
            strokeDashoffset={circumference - offset} // Инверсия заполнения
            strokeLinecap="round"
            transform="rotate(-90 25 25) " // Поворот и инверсия Y-оси
            style={{
              transition: "stroke-dashoffset 0.05s ease-out",
            }}
          />
        </svg>
      </div>
    </PageWrapper>
  );
};

export default Loading;
